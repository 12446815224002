  /* Custom CSS */

/* Reset */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-decoration: none;
  }
/* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }

  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
/* Text-Sizes */
  h1{ font-size: 40px; }
  h2{ font-size: 36px; }
  h3{ font-size: 32px; }
  h4{ font-size: 28px; }
  h5{ font-size: 24px; }
  h6{ font-size: 20px; }
  p{ font-size: 16px; line-height: 24px;}
  .h1{ font-size: 40px; }
  .h2{ font-size: 36px; }
  .h3{ font-size: 32px; }
  .h4{ font-size: 28px; }
  .h5{ font-size: 24px; }
  .h6{ font-size: 20px; }
  .p{ font-size: 16px; }
  .text-lg{font-size: 44px;}
  .text-sm{font-size: 12px;}


/* Text-Colors */
  .text-white{ color: white; }
  .text-lightgray{ color: lightgray; }
  .text-gray{ color: gray; }
  .text-darkgray{ color: #6c757d; }
  .text-black{ color: black; }
  .text-red{ color: red; }
  .text-blue{ color: blue; }
  .text-green{ color: green; }
  .text-teal{ color: teal; }
  .text-pink{ color: pink; }
  .text-purple{ color: purple; }
  .text-lightgreen{ color: lightgreen; }
  .text-yellow{ color: yellow; }
  .text-goldenrod{ color: goldenrod; }
  .text-orange{ color: orange; }
  .text-lightblue{ color: lightblue; }
  .text-coral{ color: coral; }
  .text-maroon{ color: maroon; }

  .text-info{color: #17a2b8;}
  .text-info-dark{color: #0099CC;}
  .text-warning{color: #ffc107;}
  .text-danger{color: #dc3545;}
  .text-success{color: #28a745;}
  .text-light{color: #f8f9fa;}
  .text-primary{color: #007bff;}
  .text-secondary{color: #6c757d;}
  .text-muted{color: #6c757d;}
  .text-dark{color: #343a40;}
  .text-green-custom{color: rgb(103, 168, 103);}

/* Background-Colors */
  .bg-white{ background-color: white; }
  .bg-lightgray{ background-color: lightgray; }
  .bg-gray{ background-color: gray; }
  .bg-darkgray{ background-color: darkgray; }
  .bg-black{ background-color: black; }
  .bg-red{ background-color: red; }
  .bg-blue{ background-color: blue; }
  .bg-green{ background-color: green; }
  .bg-teal{ background-color: teal; }
  .bg-pink{ background-color: pink; }
  .bg-purple{ background-color: purple; }
  .bg-lightgreen{ background-color: lightgreen; }
  .bg-yellow{ background-color: yellow; }
  .bg-goldenrod{ background-color: goldenrod; }
  .bg-orange{ background-color: orange; }
  .bg-lightblue{ background-color: lightblue; }
  .bg-coral{ background-color: coral;}
  .bg-maroon{ background-color: maroon; }

  .bg-info{background-color: #17a2b8;}
  .bg-info-dark{background-color: #0099CC;}
  .bg-warning{background-color: #ffc107;}
  .bg-danger{background-color: #dc3545;}
  .bg-success{background-color: #28a745;}
  .bg-light{background-color: #f8f9fa;}
  .bg-primary{background-color: #007bff;}
  .bg-secondary{background-color: #6c757d;}
  .bg-muted{background-color: #6c757d;}
  .bg-dark{background-color: #343a40;}
  .bg-green-custom{background-color: rgb(103, 168, 103);}

/* Opacity */
  .opaque-1{ opacity: .1;}
  .opaque-2{ opacity: .2;}
  .opaque-3{ opacity: .3;}
  .opaque-4{ opacity: .4;}
  .opaque-5{ opacity: .5;}
  .opaque-6{ opacity: .6;}
  .opaque-7{ opacity: .7;}
  .opaque-8{ opacity: .8;}
  .opaque-9{ opacity: .9;}
  .opaque-10{ opacity: .10;}

/* Font-Weight */
  .font-weight-light{font-weight: 200;}
  .font-weight-bold{font-weight: 700;}
  .font-weight-bolder{font-weight: 900;}

/* Letter-Spacing */
  .wide-letter{letter-spacing: 3px}

/* Margin */
  .m-0{margin: 0;}
  .m-1{margin: .5rem;}
  .m-2{margin: 1rem;}
  .m-3{margin: 1.5rem;}
  .m-4{margin: 2rem;}
  .m-5{margin: 2.5rem}
  .mb-0{margin-bottom: 0;}
  .mb-1{margin-bottom: .5rem;}
  .mb-2{margin-bottom: 1rem;}
  .mb-3{margin-bottom: 1.5rem;}
  .mt-0{margin-top: 0;}
  .mt-1{margin-top: .5rem;}
  .mt-2{margin-top: 1rem;}
  .mt-3{margin-top: 1.5rem;}
  .ml-0{margin-left: 0;}
  .ml-1{margin-left: .5rem;}
  .ml-2{margin-left: 1rem;}
  .ml-3{margin-left: 1.5rem;}
  .mr-0{margin-right: 0;}
  .mr-1{margin-right: .5rem;}
  .mr-2{margin-right: 1rem;}
  .mr-3{margin-right: 1.5rem;}

/* Padding */
  .p-0{padding: 0;}
  .p-1{padding: .5rem;}
  .p-2{padding: 1rem;}
  .p-3{padding: 1.5rem;}
  .p-4{padding: 2rem;}
  .p-5{padding: 2.5rem}
  .pb-0{padding-bottom: 0;}
  .pb-1{padding-bottom: .5rem;}
  .pb-2{padding-bottom: 1rem;}
  .pb-3{padding-bottom: 1.5rem;}
  .pt-0{padding-top: 0;}
  .pt-1{padding-top: .5rem;}
  .pt-2{padding-top: 1rem;}
  .pt-3{padding-top: 1.5rem;}
  .pl-0{padding-left: 0;}
  .pl-1{padding-left: .5rem;}
  .pl-2{padding-left: 1rem;}
  .pl-3{padding-left: 1.5rem;}
  .pr-0{padding-right: 0;}
  .pr-1{padding-right: .5rem;}
  .pr-2{padding-right: 1rem;}
  .pr-3{padding-right: 1.5rem;}

/* Position */
  .fixed-top{ position: fixed; top:0; }
  .fixed-bottom{ position: fixed; bottom: 0; }
  .position-absolute{position: absolute;}
  .position-relative{position: relative;}
  .bottom-0{bottom: 0;}
  .sticky-top{
    position: sticky;
    top:0;
  }
  .sticky-bottom{
    position: sticky;
    bottom:0;
  }
/* Alignment */
  .text-center{ text-align: center; }
  .text-left{ text-align: left; }
  .text-right{ text-align: right; }

/* Display */
  .d-none{ display: none; }
  .d-inline{ display: inline; }
  .d-block{ display: block; }
  .d-inline-block{ display: inline-block; }
  .d-flex{display: flex;}

/* JustifyContent */
  .justify-content-end{ justify-content: flex-end; }
  .justify-content-center{ justify-content: center; }
  .justify-content-between{ justify-content: space-between; }

/* Width */
  .w-0{width: 0%;}
  .w-05{width: 05%;}
  .w-10{width: 10%;}
  .w-15{width: 15%;}
  .w-20{width: 20%;}
  .w-25{width: 25%;}
  .w-30{width: 30%;}
  .w-33{width: 33.33%;}
  .w-35{width: 35%;}
  .w-40{width: 40%;}
  .w-45{width: 45%;}
  .w-50{width: 50%;}
  .w-55{width: 55%;}
  .w-60{width: 60%;}
  .w-65{width: 65%;}
  .w-70{width: 70%;}
  .w-75{width: 75%;}
  .w-80{width: 80%;}
  .w-90{width: 90%;}
  .w-95{width: 95%;}
  .w-100{width: 100%;}

  .w-10-px{width: 10px;}
  .w-16-px{width: 16px;}
  .w-20-px{width: 20px;}
  .w-30-px{width: 30px;}
  .w-40-px{width: 40px;}
  .w-50-px{width: 50px;}
  .w-60-px{width: 60px;}
  .w-80-px{width: 80px;}
  .w-100-px{width: 100px;}
  .w-120-px{width: 120px;}
  .w-150-px{width: 150px;}
  .w-200-px{width: 200px;}
  .w-300-px{width: 300px;}
  .w-400-px{width: 400px;}
  .w-500-px{width: 500px;}
  .w-600-px{width: 600px;}
  .w-700-px{width: 700px;}
  .w-800-px{width: 800px;}
  .w-900-px{width: 900px;}
  .w-1000-px{width: 1000px;}
  .w-1100-px{width: 1100px;}
  .w-1200-px{width: 1200px;}

/* Height */
  .h-0{height: 0%;}
  .h-05{height: 05%;}
  .h-10{height: 10%;}
  .h-15{height: 15%;}
  .h-20{height: 20%;}
  .h-25{height: 25%;}
  .h-30{height: 30%;}
  .h-35{height: 35%;}
  .h-40{height: 40%;}
  .h-45{height: 45%;}
  .h-50{height: 50%;}
  .h-55{height: 55%;}
  .h-60{height: 60%;}
  .h-65{height: 65%;}
  .h-70{height: 70%;}
  .h-75{height: 75%;}
  .h-80{height: 80%;}
  .h-90{height: 90%;}
  .h-95{height: 95%;}
  .h-100{height: 100%;}

  .h-10-px{height: 10px;}
  .h-16-px{height: 16px;}
  .h-20-px{height: 20px;}
  .h-30-px{height: 30px;}
  .h-40-px{height: 40px;}
  .h-50-px{height: 50px;}
  .h-60-px{height: 60px;}
  .h-80-px{height: 80px;}
  .h-100-px{height: 100px;}
  .h-120-px{height: 120px;}
  .h-150-px{height: 150px;}
  .h-200-px{height: 200px;}
  .h-300-px{height: 300px;}
  .h-400-px{height: 400px;}
  .h-500-px{height: 500px;}
  .h-600-px{height: 600px;}
  .h-700-px{height: 700px;}
  .h-800-px{height: 800px;}
  .h-900-px{height: 900px;}
  .h-1000-px{height: 1000px;}
  .h-1100-px{height: 1100px;}
  .h-1200-px{height: 1200px;}
/* ImageSizes */
  .img-height-32{max-height: 32px;}
  .img-height-40{max-height: 40px;}
  .img-height-60{max-height: 60px;}
  .img-height-80{max-height: 80px;}
  .img-height-100{max-height: 100px;}
  .img-height-120{max-height: 120px;}
  .img-height-150{max-height: 32px;}
  .img-height-200{max-height: 200px;}
  .img-height-300{max-height: 300px;}
  .img-height-400{max-height: 400px;}
  .img-height-500{max-height: 500px;}
  .img-height-xs{max-height: 32px;}
  .img-height-sm{max-height: 60px;}
  .img-height-md{max-height: 120px;}
  .img-height-lg{max-height: 180px;}
  .img-height-xl{max-height: 210px;}

  .img-width-32{max-width: 32px;}
  .img-width-40{max-width: 40px;}
  .img-width-60{max-width: 60px;}
  .img-width-80{max-width: 80px;}
  .img-width-100{max-width: 100px;}
  .img-width-120{max-width: 120px;}
  .img-width-150{max-width: 32px;}
  .img-width-200{max-width: 200px;}
  .img-width-300{max-width: 300px;}
  .img-width-400{max-width: 400px;}
  .img-width-500{max-width: 500px;}
  .img-width-xs{max-width: 32px;}
  .img-width-sm{max-width: 60px;}
  .img-width-md{max-width: 120px;}
  .img-width-lg{max-width: 180px;}
  .img-width-xl{max-width: 210px;}
/* Fonts */
  /* 
    <link href="https://fonts.googleapis.com/css?family=Acme|Amatic+SC|Bitter|Charmonman|Cinzel|Crimson+Text|Dancing+Script|Gloria+Hallelujah|Great+Vibes|Lobster|Lobster+Two|Mali|Oxygen|Pacifico|Permanent+Marker|Playfair+Display|Poppins|Raleway|Shadows+Into+Light|Slabo+27px|Srisakdi|Ubuntu" rel="stylesheet">
  */
  .font-acme{ font-family: 'Acme', sans-serif; }
  .font-amatic{ font-family: 'Amatic SC', cursive; }
  .font-bitter{ font-family: 'Bitter', serif; }
  .font-charmon{ font-family: 'Charmonman', cursive; }
  .font-cinzel{ font-family: 'Cinzel', serif; }
  .font-crimson{ font-family: 'Crimson Text', serif; }
  .font-dancing{ font-family: 'Dancing Script', cursive; }
  .font-fred{font-family: 'Fredericka the Great', cursive;}
  .font-gloria{ font-family: 'Gloria Hallelujah', cursive; }
  .font-great-vibes{ font-family: 'Great Vibes', cursive; }
  .font-lobster{ font-family: 'Lobster', cursive; }
  .font-lobster-two{ font-family: 'Lobster Two', cursive; }
  .font-mali{ font-family: 'Mali', cursive; }
  .font-marker{ font-family: 'Permanent Marker', cursive; }
  .font-oxygen{ font-family: "Oxygen", sans-serif; }
  .font-pacifico{ font-family: 'Pacifico', cursive; }
  .font-perm-marker{ font-family: 'Permanent Marker', cursive; }
  .font-playfair{ font-family: 'Playfair Display', serif; }
  .font-poppins{ font-family: 'Poppins', sans-serif; }
  .font-raleway{ font-family: 'Raleway', sans-serif; }
  .font-shadows{ font-family: 'Shadows Into Light', cursive; }
  .font-slabo{ font-family: 'Slabo 27px', serif; }
  .font-srisakdi{ font-family: 'Srisakdi', cursive; }
  .font-ubuntu{ font-family: 'Ubuntu', sans-serif; }

/* Borders */
  .border {border: black 1px solid;}
  .border-top{border-top: black 1px solid;}
  .border-bottom{border-bottom: black 1px solid;}
  .border-left{border-left: black 1px solid;}
  .border-right{border-right: black 1px solid;}
  .border-0{border: none;}

  .text-outline{ text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;}
/* Radius */
  .rounded{border-radius: 8px;}
  .rounded-top{ 
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .rounded-bottom{ 
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .rounded-left{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .rounded-right{
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .circle{
    border-radius: 50%;
  }
/* Spans */
  span{ display:inline }
  u{ display: inline; text-decoration: underline; }
  i{ display: inline; font-style: italic; }
  b{ display: inline; font-weight: bold;}
  small{ font-size: 12px; }

/* TextDecorations/A-tags */
  a{
    text-decoration: none;
    color: black;
    font-family: Arial, Helvetica, sans-serif;
  }
  a:hover{
    text-decoration: none;
  }
/* Container/Grid System */
  .container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  .row:before, 
  .row:after {
      content:"";
      display: table ;
      clear:both;
  }

  .col-12 { float: left; width: 100%;} 
  .col-11 { float: left; width: 91.66%;} 
  .col-10 { float: left; width: 83.33%;} 
  .col-9 { float: left; width: 75%;} 
  .col-8 { float: left; width: 66.66%;} 
  .col-7 { float: left; width: 58.33%;} 
  .col-6 { float: left; width: 50%;} 
  .col-5 { float: left; width: 41.66%;} 
  .col-4 { float: left; width: 33.33%;} 
  .col-3 { float: left; width: 25%;} 
  .col-2 { float: left; width: 16.66%;} 
  .col-1 { float: left; width: 8.33%;}

  @media (max-width: 575.98px) {
    .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1,.col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1,.col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1,
    {
      margin-left: 0px; 
      margin-right: 0px; 
      width: 100%;
    }
  }

  @media (min-width: 576px) {
    .row{ margin-left:0px; margin-right: 0px; display: block;}
    .col-sm-12 { float: left; width: 100%;} 
    .col-sm-11 { float: left; width: 91.66%;} 
    .col-sm-10 { float: left; width: 83.33%;} 
    .col-sm-9 { float: left; width: 75%;} 
    .col-sm-8 { float: left; width: 66.66%;} 
    .col-sm-7 { float: left; width: 58.33%;} 
    .col-sm-6 { float: left; width: 50%;} 
    .col-sm-5 { float: left; width: 41.66%;} 
    .col-sm-4 { float: left; width: 33.33%;} 
    .col-sm-3 { float: left; width: 25%;} 
    .col-sm-2 { float: left; width: 16.66%;} 
    .col-sm-1 { float: left; width: 8.33%;}

    .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1,.col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1,
    {
      margin-left: 0px; 
      margin-right: 0px; 
      width: 100%;
    }
  }
  @media (min-width: 768px) {
    .container {
      width: 750px;
      max-width: 750px;
    }
    .row{ margin-left:0px; margin-right: 0px; display: block;}
    .col-md-12 { float: left; width: 100%;} 
    .col-md-11 { float: left; width: 91.66%;} 
    .col-md-10 { float: left; width: 83.33%;} 
    .col-md-9 { float: left; width: 75%;} 
    .col-md-8 { float: left; width: 66.66%;} 
    .col-md-7 { float: left; width: 58.33%;} 
    .col-md-6 { float: left; width: 50%;} 
    .col-md-5 { float: left; width: 41.66%;} 
    .col-md-4 { float: left; width: 33.33%;} 
    .col-md-3 { float: left; width: 25%;} 
    .col-md-2 { float: left; width: 16.66%;} 
    .col-md-1 { float: left; width: 8.33%;}  

    .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1,
    {
      margin-left: 0px; 
      margin-right: 0px; 
      width: 100%;
    }
  }
  @media (min-width: 992px) {
    .container {
      width: 850px;
      max-width: 850px;
    }
    .row{ margin-left:0px; margin-right: 0px; display: block;}
    .col-lg-12 { float: left; width: 100%;} 
    .col-lg-11 { float: left; width: 91.66%;} 
    .col-lg-10 { float: left; width: 83.33%;} 
    .col-lg-9 { float: left; width: 75%;} 
    .col-lg-8 { float: left; width: 66.66%;} 
    .col-lg-7 { float: left; width: 58.33%;} 
    .col-lg-6 { float: left; width: 50%;} 
    .col-lg-5 { float: left; width: 41.66%;} 
    .col-lg-4 { float: left; width: 33.33%;} 
    .col-lg-3 { float: left; width: 25%;} 
    .col-lg-2 { float: left; width: 16.66%;} 
    .col-lg-1 { float: left; width: 8.33%;}  

    .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1,
    {
      margin-left: 0px; 
      margin-right: 0px; 
      width: 100%;
    }
  }
  @media (min-width: 1200px) {
    .container {
      width: 950px;
      max-width: 950px;
    }
  }

/* Components */
  .shadow-box{ box-shadow: 0px 4px 8px rgb(100,100,100); }
  .arrow-up {
    position: absolute;
    top: 0;
    margin-left: 40%;
    width: 0; 
    height: 0; 
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid maroon;
  }
  .btn:hover{
    cursor: pointer;
    background-color: rgb(92, 1, 92); 
  }
/* Custom */

  .mainImage{
  z-index: -1;
  background-image: url('/images/diversity.jpg');
  width: 100%;
  background-position-x: center;
  background-position-y: 40%;
  background-size: cover;
  background-repeat: no-repeat;
  height:300px;
  max-height: 400px;
  border-bottom: #343a40 1px solid;
  /* opacity: .8; */
  }
  .divImage{
    z-index: -1;
    width: 33.333333333333333%;
    background-position-x: center;
    background-position-y: 40%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 220px;
    max-height: 220px;
    /* opacity: .8;  */
    border: #343a40 1px solid;  
  }
  .divImageHome{
    z-index: -1;
    width: 100%;
    background-position-x: center;
    background-position-y: 40%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 260px;
    max-height: 260px;
    /* opacity: .8;  */
     
  }
  .p-neg-1{
    padding: -.1rem;
  }

  .push-home{
  width: 100%;
  height: 366px;
  }

  #wordCloudImage{
    margin-top: 40px;
    padding: 0px 8px 8px 8px;
    border-radius: 50%;
  }
/* Navbar */

  @media (max-width: 767.98px) {
    #navLinks {
      display: none;
    }
    #navSummary{
      display: none;
    }
    #sideNavOpen{
      visibility: visible;
      position: fixed;
      width: 100%;
      right: 0;
      height: 100%;
      background-color: #343a40;
      box-shadow: 0px 4px 8px rgb(100,100,100);
      z-index: 1100;
    }
    #sideNavClosed{
      visibility: hidden;
    }
    .hideLinks a{
      display: none;
    }
    .hideLinks h5{
      display: none;
    }
    .hideLinks div{
      display: none;
    }
    .hideLinks img{
      display: none;
    }
    .showLinks{
      padding: 1rem;
    }
    .showLinks a{
      display: block;
      font-weight: bold;
      padding-bottom: 2rem;
      color: lightblue;
      width: 100%;
      font-size: 22px;
    }
    .showLinks h5{
      display: inline-block;
    }
    .custom-bottom{
      position: fixed;
      bottom: 12px;
      left: 0;
      width: 100%;
    }
    .hiddenBG{
      position: fixed;
      top: 0;
      z-index: 1099;
      background-color: #343a40;
      opacity: .6;
      height: 100%;
      width: 100%;
    }
  }

  @media (min-width: 768px) {
    #navButton{
      display: none;
    }
    #sideNavOpen, #sideNavClosed{
      display: none;
    }
    .showLinks{
      display: none;
    }
    .showLinks a{
      display: none
    }
    .hideLinks{
      display: none;
    }
    .hideLinks a{
      display: none;
    }
  }

  .footerLinks div a{
    color: white;
    display: block;
    font-family: 'Poppins', sans-serif;
    line-height: 20px;
  }

  #emailSignUpDisplay input{
    box-sizing: border-box;
  }

/* HomePage */
  .link-home .link-home-text{
    visibility: hidden;
  }
  .link-home-text p{
    font-size: 15px;
    line-height: 19px;
  }
  .link-home:hover .link-home-text{
    visibility: visible;
    color: white;
    padding: .5rem;
    background-color: #343a40;
    opacity: .9;
    height: 244px;
  }
  @media (max-width: 450px){
    #pageTitle{
      font-size: 36px;
    }
  }
  .float-right{
    float: right;
    bottom: 0;
  }
  @media (max-width: 827.98px){
    .gf-number{
      position: absolute;
      top: .5rem;
      right: 0;
      opacity: .3;
      font-size: 28px;
      color: black;
    }
  }
  @media (min-width: 828px){
 
    .gf-number{
      position: absolute;
      top: .5rem;
      right: -40px;
      opacity: .3;
      font-size: 60px;
      color: black;
    }
  }

  #guidingFrameworksLinks a {
    display: block;
    margin-bottom: 1rem;
  }

  .bg-light-GF{
    background-color: #dfdfdf;
  }
  .float-left{
    float: left;
  }
  .gf-section-header{
    color: maroon;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: maroon 1px solid;
  }
  .adminInputBox{
    display: block;
    padding: 3px;
    font-size: 16px;
    margin-top: 1rem;
    width: 90%;
    border: none;
  }
  .adminInputBox::placeholder{
    font-size: 16px;
  }
  .adminInputBox-ta{
    display: block;
    padding: 3px;
    font-size: 16px;
    margin-top: 1rem;
    width: 90%;
    border: none;
    min-height: 100px;
  }
  .adminInputBox-ta::placeholder{
    font-size: 16px;
  }

  .adminInputBox-Header{
    display: block;
    padding: .5rem;
    font-size: 28px;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    width: 92%;
    border: none;
  }
  .adminInputBox-Header::placeholder{
    font-size: 28px;
  }

  #submit-Admin{
    font-size: 20px;
    padding: 5px;
    background-color: #28a745;
    border-radius: 8px;
    border: none;
    color: white;
    margin-top: 2.5rem;
  }

  .sticky-top-light{
    position: sticky;
    top: 0;
    background-color: #dfdfdf;
    padding-top: 1.5rem;
  }
  .sticky-top-white{
    position: sticky;
    top: 0;
    background-color: white;
    padding-top: 1.5rem;
    
  }

  .btn-lightblue:hover{
    background-color: #0099CC;
    color: black;
  }
  .formBtn-show{
    background-color: #28a745;
    color: white;
    font-size: 20px;
    border: none;
    padding: .25rem;
    width: 196px;
    border-radius: 8px;
  }
  .formBtn-show:hover{
    background-color: #18692b;
  }
  .formBtn-hide{
    display: none;
  }
  #partner-logo{
    width: 100%;
    height: 100px;
    margin-bottom: .5rem;
    padding-bottom: .5rem;
    border-bottom: #000 1px solid;
  }
  .border-white{
    border-color: white; 
  }